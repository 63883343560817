import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast, { Toaster } from 'react-hot-toast';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import InputLight from './Input/InputLight';


const NewSubGoal = ({ subGoals, setSubGoals }) => {
    const { t } = useTranslation();

    const [editingSubGoals, setEditingSubGoals] = useState([{ name: '' }, ...subGoals]);

    const handleValidateSubGoal = (index) => {
        //Verifier si name != ''
        if(editingSubGoals[index].name === ''){
            toast.error(t('subgoal_empty_error'));
            return ;
        }
        const newSubGoal = editingSubGoals[index];
        setSubGoals([...subGoals, newSubGoal]);
        const newEditingSubGoals = [...editingSubGoals];
        newEditingSubGoals.splice(index, 1);
        setEditingSubGoals(newEditingSubGoals);
    };

    const handleRemoveSubGoal = (index, input = false) => {
        const newSubGoals = [...subGoals];
        if(input){
            editingSubGoals.splice(index, 1);
        } else {
            newSubGoals.splice(index, 1);
        }
        setSubGoals(newSubGoals);
    };

    const styles = {
        addNewSubGoalBtn: {
          width: '140px',
          padding: '10px',
          borderRadius: '15px',
          margin: '20px auto 40px auto',
          backgroundColor: '#D3D3D3',
          cursor: 'pointer',
          color: '#000',
          fontSize: '15px',
          border: '2px solid #000',
        },
        divStyle: {
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
        }
    }

    return (
        <div>
            {subGoals.map((subGoal, index) => (
                <div key={index} style={styles.divStyle}>
                    {subGoal.name}
                    <FaTimes style={{color:'red', fontSize:'20px', cursor:'pointer', marginLeft:'5px'}}
                            onClick={() => handleRemoveSubGoal(index)}
                            className="cancel-goal-button" 
                                /> 
               </div>
            ))}

            {editingSubGoals.map((subGoal, index) => (
                <div key={index} style={styles.divStyle} >
                    <InputLight name={'new_subgoal-name'}
                                placeholder={t('placeholder_new_subgoal')}
                                onChange={(value) => {
                                    const newEditingSubGoals = [...editingSubGoals];
                                    newEditingSubGoals[index].name = value;
                                    setEditingSubGoals(newEditingSubGoals);
                                }}
                                value={subGoal.name}
                                type={'text'}
                            
                    // <input
                    //     type="text"
                    //     placeholder={t('placeholder_new_subgoal')}
                    //     style={{padding:'5px'}}
                    //     value={subGoal.name}
                    //     onChange={(e) => {
                    //         const newEditingSubGoals = [...editingSubGoals];
                    //         newEditingSubGoals[index].name = e.target.value;
                    //         setEditingSubGoals(newEditingSubGoals);
                    //     }}
                     />
                        <FaCheck style={{color:'green', fontSize:'20px', margin:'5px'}}
                                onClick={() => handleValidateSubGoal(index)}
                                className="save-goal-button"
                                />
                        <FaTimes style={{color:'red', fontSize:'20px', margin:'5px'}}
                                onClick={() => handleRemoveSubGoal(index, true)}
                                className="cancel-goal-button" 
                                />
                        <Tooltip anchorSelect=".save-goal-button" direction="top"> {t('save_button')} </Tooltip>
                        <Tooltip anchorSelect=".cancel-goal-button" direction="top"> {t('cancel_button')} </Tooltip>
                </div>
            ))}

            <div style={styles.addNewSubGoalBtn} onClick={() => setEditingSubGoals([...editingSubGoals, { name: '' }])}>
                {t('add_other_subgoal')}
            </div>
        </div>
    );
};

export default NewSubGoal;
